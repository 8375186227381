<template>
  <div>
          <!-- 비상사태구분 -->
    <!-- <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="EAP_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="emergencyTypeCd"
            label="비상사태구분"
            v-model="searchParam.emergencyTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box> -->
    <c-table
      ref="table"
      title="훈련 시나리오 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :isExpand="true"
      :usePaging="false"
      :columnSetting="false"
      rowKey="trainingScenarioNo"
      :selection="popupParam.type"
      @rowDblclick="rowDblclick"
    >
      <template v-slot:expand-header>
        훈련 시나리오 상세
      </template>
      <template v-slot:expand-td="props">
        <c-card
          v-for="(item, index) in props.row.emergencyScenarioDetailModelList"
          :key="index"
          :noHeader="true">
          <template slot="card-detail">
            <div class="col-12">
              <div class="row">
                <!-- <div class="col-2">
                  <c-text 
                    :disabled="true"
                    :editable="editable"
                    label="LBLSORTORDER" 
                    name="sortOrder"
                    v-model="item.sortOrder">
                  </c-text>
                </div> -->
                <div class="col-2">
                  <c-text 
                    :disabled="true"
                    :editable="editable"
                    label="소요시간" 
                    name="leadTime"
                    v-model="item.leadTime">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-text 
                    :disabled="true"
                    :editable="editable"
                    label="LBLITEMS" 
                    name="itemName"
                    v-model="item.itemName">
                  </c-text>
                </div>
                <div class="col-4">
                  <c-text 
                    :disabled="true"
                    :editable="editable"
                    label="상세훈련내용" 
                    name="trainingDetailContents"
                    v-model="item.trainingDetailContents">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-text 
                    :disabled="true"
                    :editable="editable"
                    label="LBLMANAGER" 
                    name="managerUserName"
                    v-model="item.managerUserName">
                  </c-text>
                </div>
              </div>
            </div>
          </template>
        </c-card>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergenct-action-plan-docu',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'multiple',
          trainingScenarioNo: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'planDocu',
            field: 'planDocu',
            label: '비상조치계획',
            align: 'center',
            child: [
              {
                name: 'plantName',
                field: 'plantName',
                label: 'LBLPLANT',
                align: 'center',
                sortable: true,
                style: 'width:60px',
              },
              {
                name: 'emergencyPlanName',
                field: 'emergencyPlanName',
                label: '계획서명',
                align: 'center',
                sortable: true,
                style: 'width:150px',
              },
            ]
          },
          {
            name: 'traningScnario',
            field: 'traningScnario',
            label: '훈련시나리오',
            align: 'center',
            child: [
              {
                name: 'revisionNum',
                field: 'revisionNum',
                label: 'Rev.',
                align: 'center',
                sortable: true,
                style: 'width:60px',
              },
              {
                name: 'trainingScenarioName',
                field: 'trainingScenarioName',
                label: '훈련시나리오명',
                align: 'center',
                sortable: true,
                style: 'width:150px',
              },
              {
                name: 'trainingContentsSummary',
                field: 'trainingContentsSummary',
                label: '훈련내용요약',
                align: 'left',
                sortable: true,
                style: 'width:450px',
              },
            ]
          },
        ],
        data: [],
      },
      subGrid: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:5%',
            sortable: false,
          },
          {
            name: 'leadTime',
            field: 'leadTime',
            label: '소요시간',
            align: 'center',
            minuteStep: 10,
            style: 'width:10%',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: 'LBLITEMS',
            align: 'center',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'trainingDetailContents',
            field: 'trainingDetailContents',
            label: '상세훈련내용',
            align: 'center',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'managerUserName',
            field: 'managerUserName',
            label: 'LBLMANAGER',
            align: 'center',
            style: 'width:15%',
            sortable: false,
          },
        ],
        height: '440px',
        data: [],
      },
      searchParam: {
        trainingScenarioNo: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = this.contentHeight - 200;
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.sop.eap.scenario.list.url;
      // url setting
      // code setting
      this.searchParam.trainingScenarioNo = this.popupParam.trainingScenarioNo;
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '시나리오를 선택하세요.', // 설비를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
